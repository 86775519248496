import React from 'react';
import { BsLinkedin, BsGithub, BsTelegram } from 'react-icons/bs';

const SocialMedia = () => (
  <div className="app__social">
    <div>
      <a
        href="https://www.linkedin.com/in/kaveh-aidivandi-a793191a1/"
        target="_blank"
        rel="noreferrer"
      >
        <BsLinkedin />
      </a>
    </div>
    <div>
      <a href="https://github.com/xolmer" target="_blank" rel="noreferrer">
        <BsGithub />
      </a>
    </div>
    <div>
      <a href="https://t.me/aidivandi" target="_blank" rel="noreferrer">
        <BsTelegram />
      </a>
    </div>
    {/* custom icon upwork */}
    <div>
      <a
        href="https://www.upwork.com/freelancers/~01a1039c45cb85dad5"
        target="_blank"
        rel="noreferrer"
      >
        <img src="upwork.png" alt="Upwork" height={20} />
      </a>
    </div>
  </div>
);

export default SocialMedia;
